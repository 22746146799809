<template>
  <div class="container container--rows">
    <div class="row">
      <p class="paragraph">
        Make sure you use the right input type's. So
        <span class="code-tag">type="tel"</span> for phone numbers for example.
      </p>

      <form class="form" id="styleguideForm">
        <h3 class="item-title">Stuur ons een mailtje</h3>
        <label for="name">Name</label>
        <input id="name" type="text" placeholder="Jane Doe" autocorrect="off" required>

        <label for="tel">Telefoonnummer</label>
        <input
          id="tel"
          name="tel"
          type="tel"
          autocorrect="off"
          autocomplete="tel"
          placeholder="06 12345678"
        >

        <label for="email">E-mailadres</label>
        <input
          id="email"
          name="email"
          type="email"
          autocapitalize="off"
          autocorrect="off"
          autocomplete="email"
          placeholder="janedoe@example.com"
          required
        >

        <label for="postalcode">Postcode</label>
        <input
          id="postalcode"
          name="postalcode"
          type="text"
          pattern="\d*"
          novalidate
          autocorrect="off"
          autocomplete="postal-code"
          placeholder="1234AB"
        >

        <label for="street">Straatname</label>
        <input
          id="street"
          name="street"
          type="text"
          autocorrect="off"
          autocomplete="address-line1"
          placeholder="Thuissportlaan 12"
        >

        <label for="city">Woonplaats</label>
        <input
          id="city"
          name="city"
          type="text"
          autocorrect="off"
          autocomplete="address-level2"
          placeholder="Amsterdam"
        >

        <label for="message">Bericht</label>
        <textarea id="message" name="message" placeholder="Type hier je bericht" required></textarea>

        <button
          class="button button--primary"
          type="submit"
          form="styleguideForm"
          value="Submit"
        >Verstuur</button>
      </form>
    </div>

    <div class="row">
      <styleguide-oneliner/>
    </div>
  </div>
</template>

<script>
export default {
  name: "styleguideForms",
  data() {
    return {
     
    };
  },
  methods: {
    
  },
  mounted () {
    
  },
  destroyed () {
    //remove videoplayer
  }
};
</script>
