<template>
  <div :ref="this.id">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "VueStyle",
  props: {
    id: {
      default: 'vuestyle',
      type: String
    }
  },
  data() {
    return {
      
    };
  },
  methods: {
   
  },
  mounted () {
    const style = document.createElement("style");
    
    if(this.$slots.default){
      //If style is passed via the slot
      style.innerHTML = this.$refs[this.id].innerText;
      this.$refs[this.id].innerHTML = '';
    }
    
    style.setAttribute("id", this.id);
    this.$refs[this.id].appendChild(style);
  }
};
</script>
