/* global  process */
import Vue from "vue";
import axios from "axios";
import "./components";

Vue.prototype.$http = axios;
const baseURL = process.env.VUE_APP_API_URL;
if (typeof baseURL !== "undefined") {
  axios.defaults.baseURL = baseURL;
}
Vue.config.productionTip = false;

import "./main.scss";

import "lazysizes";
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.customMedia = {
  "--mobile": "(max-width: 768px)"
};

//Eventbus to communicate between components
const EventBus = new Vue();
Vue.prototype.$bus = EventBus;

new Vue({
  el: "#app",
  delimiters: ["${", "}"]
});
