<template>
  <header class="main-menu" :class="[minimal ? 'minimal' : null]">
    <slot name="menu-left"></slot>

    <slot name="logo"></slot>

    <slot name="menu-right"></slot>

    <div class="menu-mobile mobile">
      <button @click="switchMobileMenu" class="icon-close"></button>
      <slot name="menu-mobile"></slot>
    </div>
    <button
      @click="switchMobileMenu"
      id="mobile_menu_button"
      class="icon-hamburger mobile"
    ></button>
  </header>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      minimal: false,
      mobileMenuActive: false
    };
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 150) {
        this.minimal = true;
      } else {
        this.minimal = false;
      }
    },
    setScroll() {
      window.addEventListener("scroll", this.handleScroll);
    },
    mobileSwitch() {
      if (this.active) {
        this.active = false;
        this.transition = true;
        const that = this;
        setTimeout(function() {
          that.transition = false;
        }, 300);
      } else {
        this.active = true;
      }
    },
    switchMobileMenu() {
      const el = document.body;

      if (this.mobileMenuActive === false) {
        el.classList.add("mobile-menu-active");
        this.mobileMenuActive = true;
      } else {
        el.classList.remove("mobile-menu-active");
        this.mobileMenuActive = false;
      }
    }
  },
  created() {
    this.setScroll();
    this.viewHeight = window.innerHeight;
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>
