<template>
  <div class="container container--rows">
    <div class="row">
      <p class="paragraph">
        The font used for all Fitchannel typography is
        <strong>Roboto</strong>. Here's the
        <a href="https://fonts.google.com/specimen/Roboto">Google Fonts link</a>.
        <br>Font-sizes change to a smaller size when the screen hits the breaking point of
        <span
          class="code-tag"
        >768px</span>.
        <br>The paragraphs have a maximum width of
        <span class="code-tag">750px</span>.
      </p>
      <h2 class="section-title no-margin">Section title</h2>
      <p class="paragraph typography-info">
        <span class="code-tag">.section-title</span> Roboto • Bold italic • 48px • Line height: 44px
      </p>

      <h3 class="section-subtitle no-margin">Section subtitle</h3>
      <p class="paragraph typography-info">
        <span class="code-tag">.section-subtitle</span> Roboto • Light • 24px • Line height: 26px
      </p>

      <h4 class="item-title no-margin">Item title</h4>
      <p class="paragraph typography-info">
        <span class="code-tag">.item-title</span> Roboto • Bold italic • 24px • Line height: 26px
      </p>

      <h5 class="item-subtitle no-margin">Item subtitle</h5>
      <p class="paragraph typography-info">
        <span class="code-tag">.item-subtitle</span> Roboto • Light • 20px • Line height: 22px
      </p>

      <h5 class="item-subtitle no-margin">Paragraph title</h5>
      <p class="paragraph typography-info">
        <span class="code-tag">.paragraph-title</span> Roboto • Bold • 22px • Line height: 26px
      </p>

      <p
        class="paragraph no-margin"
      >Paragraph. Morbi sed dictum massa. Nam a tincidunt elit. Maecenas posuere nibh felis, eget semper urna aliquet eget. Curabitur sit amet orci ac neque placerat sodales vel ut ante.</p>
      <p class="paragraph typography-info">
        <span class="code-tag">.paragraph</span> Roboto • Light • 18px • Line height: 24px • Max. width: 750px / 60 char. p/line
      </p>
    </div>
    <div class="row">
      <h2 class="item-title styleguide-title">Typography example</h2>
      <h2 class="section-title">Nice big section title</h2>
      <h3 class="section-subtitle">Medium to short subtitle</h3>
      <p
        class="paragraph"
      >Paragraph. Morbi sed dictum massa. Nam a tincidunt elit. Maecenas posuere nibh felis, eget semper urna aliquet eget. Curabitur sit amet orci ac neque placerat sodales vel ut ante. Suspendisse iaculis nulla et ultricies rutrum. Phasellus neque nisl, tristique eget nisl vel, sagittis consequat nisi. Fusce iaculis imperdiet imperdiet.</p>
      <h4 class="item-title">Title for an item</h4>
      <h5 class="item-subtitle">Short subtitle</h5>
      <p
        class="paragraph"
      >Paragraph. Morbi sed dictum massa. Nam a tincidunt elit. Maecenas posuere nibh felis, eget semper urna aliquet eget. Curabitur sit amet orci ac neque placerat sodales vel ut ante. Suspendisse iaculis nulla et ultricies rutrum. Phasellus neque nisl, tristique eget nisl vel, sagittis consequat nisi. Fusce iaculis imperdiet imperdiet.</p>
    </div>

    <div class="row">
      <styleguide-oneliner/>
    </div>
  </div>
</template>

<script>
export default {
  name: "styleguideTypography",
  data() {
    return {
     
    };
  },
  methods: {
    
  },
  mounted () {
    
  },
  destroyed () {
    //remove videoplayer
  }
};
</script>
