<template>
  <div class="container container--rows">
    <div class="row">
      <p class="paragraph">
        Buttons always start with a
        <span class="code-tag">.button</span> class. After that they're specified with other classes.
        <br>The buttons include an extensive hover-state which is sometimes hard to overwrite. For example; when you want a primary button to change color when it's in a blue :odd element. You can use the SCSS
        <span
          class="code-tag"
        >@include</span> which includes the properties of that element without a wrapping class.
      </p>

      <div class="styleguide-buttons">
        <p class="paragraph">
          <button class="button button--primary">Button</button>
          <span class="code-tag">.button</span>
          <span class="code-tag">.button--primary</span>
          <br>
          <span class="code-tag">@include button--primary();</span>
        </p>

        <p class="paragraph">
          <button class="button button--white">Button</button>
          <span class="code-tag">.button</span>
          <span class="code-tag">.button--white</span>
          <br>
          <span class="code-tag">@include button--white();</span>
        </p>

        <p class="paragraph">
          <button class="button button--outline">Button</button>
          <span class="code-tag">.button</span>
          <span class="code-tag">.button--outline</span>
          <br>
          <span class="code-tag">@include button--outline();</span>
        </p>

        <p class="paragraph">
          <button class="button button--outline button--white">Button</button>
          <span class="code-tag">.button</span>
          <span class="code-tag">.button--outline</span>
          <span class="code-tag">.button--white</span>
          <br>
          <span class="code-tag">@include button--outline--white();</span>
        </p>

        <p class="paragraph">
          <button class="button button--primary">
            Button
            <span class="loading-dots">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </span>
          </button>
          Add a
          <span class="code-tag">span</span> with
          <span class="code-tag">.loading-dots</span> containing three
          <span class="code-tag">span's</span> for a loader animation
        </p>
      </div>
    </div>

    <div class="row">
      <styleguide-oneliner/>
    </div>
  </div>
</template>

<script>
export default {
  name: "styleguideButtons",
  data() {
    return {
     
    };
  },
  methods: {
    
  },
  mounted () {
    
  },
  destroyed () {
    //remove videoplayer
  }
};
</script>
