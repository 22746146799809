<template>
  <section id="styleguide">
    <div class="styleguide__header">
      <div class="container">
        <h2 class="section-title">{{ currentItem }}</h2>
      </div>
    </div>
    <div class="styleguide__menu">
      <div @click="makeActive('styleguide')" :class="{ 'active' : items.styleguide}">Styleguide</div>
      <div @click="makeActive('colors')" :class="{ 'active' : items.colors}">Colors</div>
      <div @click="makeActive('typography')" :class="{ 'active' : items.typography}">Typography</div>
      <div @click="makeActive('grid')" :class="{ 'active' : items.grid}">Grid</div>
      <div @click="makeActive('buttons')" :class="{ 'active' : items.buttons}">Buttons</div>
      <div @click="makeActive('forms')" :class="{ 'active' : items.forms}">Forms</div>
      <div @click="makeActive('images')" :class="{ 'active' : items.images}">Images</div>
    </div>
    <div class="styleguide__content">
      <styleguide-intro v-if="items.styleguide"/>
      <styleguide-colors v-if="items.colors"/>
      <styleguide-typography v-if="items.typography"/>
      <styleguide-grid v-if="items.grid"/>
      <styleguide-buttons v-if="items.buttons"/>
      <styleguide-forms v-if="items.forms"/>
      <styleguide-images v-if="items.images"/>
    </div>
  </section>
</template>

<script>
export default {
  name: "Styleguide",
  data() {
    return {
      items: {
        styleguide: true,
        colors: false,
        typography: false,
        grid: false,
        buttons: false,
        forms: false,
        images: false,
      },
      currentItem: "styleguide"
    };
  },
  methods: {
    makeActive(currentItem){
      for (var item in this.items) {
        this.items[item] = false;
      }
      this.items[currentItem] = true;
      this.currentItem = currentItem;
    }
  },
  mounted () {
    
  },
  destroyed () {
    //remove videoplayer
  }
};
</script>
