<template>
  <div class="container container--rows">
    <div class="row">
      <p class="paragraph">
        Images are hosted on Amazon.
        <br>The images are lazyloaded with
        <a
          href="https://github.com/aFarkas/lazysizes"
          target="_blank"
        >lazysizes</a>. Add
        <span class="code-tag">.lazyload</span>.
      </p>

      <p class="paragraph">
        There's a Twig component to load images. Find it at
        <span
          class="code-tag"
        >templates/components/image.twig</span>
        . This component automaticly resizes the images for the srcset, has a mobile option (for when the client wants a vertical image for mobile and horizontal for desk, for example). These images are automaticly lazyloaded.
        <br>
        <strong>How to use</strong>
        Provide the maximum amount of width (in respect of the view) the image is going to take. This determines the resolution for the srcset images. (We don't need a 2000px image if an image only take up half the screen, for example).
        <br>
        <br>Example: an image with a variant for mobile, using the full width of the page with a class of "image" and a class of "image--blue".
        <span
          class="code-tag"
        >{% embed "components/image.twig" with {'image': block.image.one(), 'image_mobile': block.imageMobile.one(), 'width': 100, 'classes': 'image image--blue' } %}{% endembed %}</span>
        <br>
        <br>Example: an image using maximum 25% of the screenwidth
        <span
          class="code-tag"
        >{% embed "components/image.twig" with {'image': block.image.one() 'width': 25 } %}{% endembed %}</span>
        <br>
        <br>Minimaly required: an image
        <span
          class="code-tag"
        >{% embed "components/image.twig" with {'image': block.image.one() } %}{% endembed %}</span>
      </p>
    </div>

    <div class="row">
      <styleguide-oneliner/>
    </div>
  </div>
</template>

<script>
export default {
  name: "styleguideImages",
  data() {
    return {
     
    };
  },
  methods: {
    
  },
  mounted () {
    
  },
  destroyed () {
    //remove videoplayer
  }
};
</script>
