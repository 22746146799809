<template>
  <li :class="['nav-item', open ? 'open' : null]">
    <div v-if="children > 0" class="nav_open" @click="open = open ? false : true"></div>
    <slot name="menu-mobile-children"></slot>
  </li>
</template>

<script>
export default {
  name: "NavigationItem",
  props: {
    children: String
  },
  data() {
    return {
      open: false
    };
  },
  methods: {

  }
};
</script>
