<template>
  <div class="container container--rows">
    <div class="row">
      <p class="paragraph">
        This is a work in progress. Inspiration is gathered from
        <a
          target="_blank"
          href="https://rizzo.lonelyplanet.com/styleguide/design-elements/colours"
        >Lonely planet</a>,
        <a
          target="_blank"
          href="https://dsmexample.invisionapp.com/dsm/in-vision-dsm/example-library"
        >Invision</a>,
        <a target="_blank" href="https://frontify.com/styleguide?ref=producthunt">Frontify</a> and
        <a
          target="_blank"
          href="https://material.io/design/introduction/#principles"
        >Material design</a>.
      </p>
      <p></p>

      <styleguide-oneliner/>
    </div>
  </div>
</template>

<script>
export default {
  name: "styleguideIntro",
  data() {
    return {
     
    };
  },
  methods: {
    
  },
  mounted () {
    
  },
  destroyed () {
    //remove videoplayer
  }
};
</script>
