<template>
  <div class="container container--rows">
    <div class="row">
      <p class="paragraph">
        All colors used by Fitchannel are shown here. The upper row shows the primary colors and grey tones. The lower row shows the colors associated with each trainer. These are strictly used on their respective pages.
        <br>Included are the HEX-code and the SCSS-variable.
      </p>
      <div class="styleguide-colors">
        <div class="color bkgr-primary">
          <p>Primary</p>
          <p class="hexcode">#1860AF</p>
          <p class="scssCode">$primary</p>
        </div>
        <div class="color multi-color bkgr-primary">
          <div class="bkgr-primary25">
            <p class="hexcode">#C5D7EA</p>
            <p class="scssCode">$primary25</p>
          </div>
          <div class="bkgr-primary50">
            <p class="hexcode">#8BAFD7</p>
            <p class="scssCode">$primary50</p>
          </div>
          <div class="bkgr-primary75">
            <p class="hexcode">#5187C3</p>
            <p class="scssCode">$primary75</p>
          </div>
          <div class="bkgr-primary">
            <p class="hexcode">#1860AF</p>
            <p class="scssCode">$primary</p>
          </div>
        </div>
        <div class="color bkgr-secondary">
          <p>Secondary</p>
          <p class="hexcode">#FF6D00</p>
          <p class="scssCode">$secondary</p>
        </div>
        <div class="color bkgr-black">
          <p>Black</p>
          <p class="hexcode">#000000</p>
          <p class="scssCode">$black</p>
        </div>
        <div class="color bkgr-darkgrey">
          <p>Darkgrey</p>
          <p class="hexcode">#4A4A4A</p>
          <p class="scssCode">$darkgrey</p>
        </div>
        <div class="color bkgr-fontcolor">
          <p>Font color</p>
          <p class="hexcode">#4A4A4A</p>
          <p class="scssCode">$font-color</p>
        </div>
        <div class="color bkgr-grey">
          <p>Grey</p>
          <p class="hexcode">#9B9B9B</p>
          <p class="scssCode">$grey</p>
        </div>
        <div class="color bkgr-lightgrey">
          <p>Lightgrey</p>
          <p class="hexcode">#F2F2F2</p>
          <p class="scssCode">$lightgrey</p>
        </div>
        <div class="color bkgr-white">
          <p>White</p>
          <p class="hexcode">#FFFFFF</p>
          <p class="scssCode">$white</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="styleguide-colors">
        <div class="color theme-bkgr-mark">
          <p>Fit</p>
          <p>met Mark</p>
          <p class="hexcode">#FF6D00</p>
          <p class="scssCode">$mark</p>
        </div>
        <div class="color theme-bkgr-carlos">
          <p>Afvallen</p>
          <p>met Carlos</p>
          <p class="hexcode">#FFD34D</p>
          <p class="scssCode">$carlos</p>
        </div>
        <div class="color theme-bkgr-radmilo">
          <p>Spieren</p>
          <p>met Radmilo</p>
          <p class="hexcode">#344130</p>
          <p class="scssCode">$radmilo</p>
        </div>
        <div class="color theme-bkgr-remy">
          <p>Boksen</p>
          <p>met Remy</p>
          <p class="hexcode">#EA4040</p>
          <p class="scssCode">$remy</p>
        </div>
        <div class="color theme-bkgr-yogadebasis">
          <p>Yoga de Basis</p>
          <p>met Myrna</p>
          <p class="hexcode">#9CDFE1</p>
          <p class="scssCode">$yogadebasis</p>
        </div>
        <div class="color theme-bkgr-yogafitslank">
          <p>Yoga Fit &amp; Slank</p>
          <p>met Danielle</p>
          <p class="hexcode">#BDE4A7</p>
          <p class="scssCode">$yogafitslank</p>
        </div>
        <div class="color theme-bkgr-mtvfit">
          <p>MTV Fit</p>
          <p>met Kay</p>
          <p class="hexcode">#F3FE68</p>
          <p class="scssCode">$mtvfit</p>
        </div>
        <div class="color theme-bkgr-kasia">
          <p>Perfect Body</p>
          <p>met Kasia</p>
          <p class="hexcode">#F51370</p>
          <p class="scssCode">$kasia</p>
        </div>
        <div class="color theme-bkgr-circuittraining">
          <p>Circuittraining</p>
          <p>met Carlos</p>
          <p class="hexcode">#6F2DBD</p>
          <p class="scssCode">$circuittraining</p>
        </div>
        <div class="color theme-bkgr-groove">
          <p>Dansen</p>
          <p>met Iris</p>
          <p class="hexcode">#000000</p>
          <p class="scssCode">$groove</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="styleguide-colors">
        <div class="color bkgr-primarygradient">
          <p>Primary gradient</p>
          <p class="hexcode">#5288C3 - #3FCCDE</p>
          <p class="scssCode">$primary-gradient</p>
        </div>
      </div>
    </div>

    <div class="row">
      <styleguide-oneliner/>
    </div>
  </div>
</template>

<script>
export default {
  name: "styleguideColors",
  data() {
    return {
     
    };
  },
  methods: {
    
  },
  mounted () {
    
  },
  destroyed () {
    //remove videoplayer
  }
};
</script>
