<template>
  <div :ref="this.id">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "VueScript",
  props: {
    src: String,
    id: {
      default: 'vuescript',
      type: String
    }
  },
  data() {
    return {
      
    };
  },
  methods: {
   
  },
  mounted () {
    const script = document.createElement("script");
    
    if(this.$slots.default){
      //If script is passed via the slot
      script.innerHTML = this.$refs[this.id].innerText;
      this.$refs[this.id].innerHTML = '';
    }else{
      //Script is passed via "src"
      script.setAttribute(
        "src",
        this.src
      );
      script.setAttribute("async", "");
    }
    
    script.setAttribute("type", "text/javascript");
    script.setAttribute("id", this.id);
    this.$refs[this.id].appendChild(script);
  }
};
</script>
