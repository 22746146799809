<template>
  <div class="instagram-feed__item" @click="popup = true">
    <img :src="image" alt="" />
    <div
      class="instagram-feed-popup"
      v-if="popup === true"
      @click.prevent="popup = false"
    >
      <div class="instagram-feed-popup__content">
        <img :src="image" alt="" class="instagram-feed-popup__image" />
        <div class="instagram-feed-popup__footer">
          <p class="paragraph">{{ likes }} likes</p>
          <p class="paragraph">{{ comments }} comments</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Insta",
  props: {
    url: String,
    image: String,
    likes: String,
    comments: String
  },
  data() {
    return {
      popup: false
    };
  },
  methods: {}
};
</script>
