<template>
  <div class="quote">
    <p class="paragraph" v-html="quotes[number].quote"></p>
    <p class="paragraph">- {{ quotes[number].name }}</p>
  </div>
</template>

<script>
export default {
  name: "styleguideOneliner",
  data() {
    return {
      number: 2,
      quotes: [
        {
          name: "Unknown",
          quote: "Weeks of coding can save you hours of planning."
        },
        {
          name: "Martin Fowler",
          quote: "Any fool can write code that a computer can understand. Good programmers write code that humans can understand."
        },
        {
          name: "Davy Greenberg",
          quote: "If I do a job in 30 minutes it’s because I spent 10 years learning how to do that in 30 minutes. You owe me for the years, not the minutes."
        },
        {
          name: "John Johnson",
          quote: "First, solve the problem. Then, write the code."
        },
        {
          name: "Antoine de Saint-Exupery",
          quote: "Perfection is achieved not when there is nothing more to add, but rather when there is nothing more to take away."
        },
        {
          name: "Cory House",
          quote: "Code is like humor. When you have to explain it, it’s bad."
        },
        {
          name: "Anne Jan",
          quote: "Een project zonder README overgedragen krijgen is als een toilet dat door vorige gebruiker niet doorgespoeld is :shit:"
        },
        {
          name: "Martin Fowler",
          quote: "When to use iterative development? You should use iterative development only on projects that you want to succeed."
        },
        {
          name: "Kent Beck",
          quote: "Optimism is an occupational hazard of programming: feedback is the treatment."
        },
        {
          name: "Dan Solomon",
          quote: "Sometimes it pays to stay in bed on Monday, rather than spending the rest of the week debugging Monday’s code."
        },
        {
          name: "Oscar Wilde",
          quote: "Experience is the name everyone gives to their mistakes."
        },
        {
          name: "Björn",
          quote: "Kees is typing..."
        },
      ]
    };
  },
  methods: {
    
  },
  mounted () {
    let quoteAmount = this.quotes.length;
    this.number = Math.floor(Math.random() * Math.floor(quoteAmount));
  },
};
</script>

<style lang="scss" scoped>
.quote {
  padding: 20px;
  width: fit-content;
  max-width: 750px;
  border-radius: 5px;
  background: #f2f2f2;

  .paragraph:first-child {
    margin: 0;

    &:before {
      content: open-quote;
    }

    &:after {
      content: close-quote;
    }

    &:before,
    &:after {
      font-size: 18px;
      color: #1860af;
    }
  }

  .paragraph:last-child {
    text-align: right;
    color: #1860af;
    margin: 0;
  }
}
</style>
