<template>
  <div class="container container--rows">
    <div class="row">
      <p class="paragraph">The grid is built up from multitudes of 8. So 8px, 16px, 32px, etc.</p>

      <h4 class="item-title">Colums</h4>
      <p class="paragraph">The grid is built up from multitudes of 8. So 8px, 16px, 32px, etc.</p>

      <h4 class="item-title">gaps</h4>
      <p class="paragraph">The grid is built up from multitudes of 8. So 8px, 16px, 32px, etc.</p>

      <h4 class="item-title">margins</h4>
      <p class="paragraph">The grid is built up from multitudes of 8. So 8px, 16px, 32px, etc.</p>
    </div>

    <div class="row">
      <styleguide-oneliner/>
    </div>
  </div>
</template>

<script>
export default {
  name: "styleguideGrid",
  data() {
    return {
     
    };
  },
  methods: {
    
  },
  mounted () {
    
  },
  destroyed () {
    //remove videoplayer
  }
};
</script>
